<template>
  <section class="room">

    <p v-if="plan > 0" class="text-left"> {{ $t('option') }} {{ plan }} </p>
    <div class="card">
      <div class="plan-room-type">
        <p class="room-type" v-for="(room, index) in rooms" :key="index">
          {{ room.name }}
        </p>
      </div>

      <div class="reservation-info">
        <div>
          <p>{{ $tc('from', 2) }} {{ getDayOfWeek(checkIn, this.site.language) }} {{ formatDate(checkIn) }} {{ $t('to') }} {{ getDayOfWeek(checkOut, this.site.language) }} {{ formatDate(checkOut) }}</p>
          <p>{{ meal }}</p>
          <p>{{ $t('vatIncluded') }}</p>
          <p>{{ rateID }}</p>
          <p v-if="checkNoRefund()">
            {{ $t('nonRefundable') }}
            <span class="show-more" @click="showPolicies()"> <i class="fa fa-info-circle"/></span>
          </p>
          <p v-else>
            {{ $t('flexRate') }}
            <span class="show-more" @click="showPolicies()"> <i class="fa fa-info-circle"/></span>
          </p>
        </div>
      </div>

      <div class="price">
        <div class="total lg">
          <p>{{ $t('totalPrice') }}</p>
          <p class="price-calc">{{ price.toFixed(2) }}€</p>
          <p>{{ rooms.length }} {{ $t('roomEs') }}, {{ nights }} {{ $t('nightsStay') }}</p>
        </div>
        <div class="average">
          <p>{{ averagePrice() }}€</p>
          <p>{{ $t('averagePriceNight') }}</p>
        </div>
        <a v-if="btnActive" @click="rateSelected" target="_blank" rel="noopener noreferrer" class="btn btn-primary">{{ $t('bookNow') }}</a>
      </div>
    </div>
  </section>

  <div v-if="showModal" class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
            <button class="modal-default-button" @click="showPolicies()">
              <img src="./../../assets/list-page/bt_map_close_map_default.svg" alt="" srcset="">
            </button>
            <h3>{{ $tc('cancellationPolicy', 2) }}</h3>
        </div>
        <div class="modal-body">
          <p v-if="checkNoRefund()">
            {{ $t('nonRefundableChooseRoomText') }}
          </p>
          <p v-else v-for="(item, index) in this.cancellationPolicies"
            :key="index"
          >
            {{showAllCancellationPolicies(item)}}
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Constants from '@/constants'
import {formatDate, getDayOfWeek} from '@/helpers/utils'
import {mapGetters} from 'vuex'

export default {
  components: {},
  props: {
    rooms: Array,
    nights: Number,
    price: Number,
    cancellationPolicies: Array,
    checkIn: String,
    checkOut: String,
    meal: String,
    rateId: String,
    plan: Number,
    bookToken: String,
    btnActive: Boolean,
  },
  computed: {
    ...mapGetters({site: 'site'})
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    formatDate: formatDate,
    getDayOfWeek: getDayOfWeek,
    showCancellationPolicies() {
      let cancellationPolicies = ""
      let from = formatDate(this.cancellationPolicies[0].from)
      let to = formatDate(this.cancellationPolicies[0].to)
      if (this.cancellationPolicies[0].pricing.sell.price === 0) {
        cancellationPolicies += `${this.$i18n.t('freeCancellationsUntil')} ${to} \n`
      } else if (from == to) {
        cancellationPolicies += `${this.$i18n.t('forCancellationsOn')} ${from} ${this.$i18n.t('beACharge')} ${this.cancellationPolicies[0].pricing.sell.price} ${this.$i18n.t('euros')}. \n`
      } else {
        cancellationPolicies += `${this.$i18n.t('forCancellationsBetween')} ${from} ${this.$i18n.t('and')} ${to} ${this.$i18n.t('beACharge')} ${this.cancellationPolicies[0].pricing.sell.price} ${this.$i18n.t('euros')}. \n`
      }
      return cancellationPolicies
    },
    showAllCancellationPolicies(item) {
      let from = formatDate(item.from)
      let to = formatDate(item.to)
      if (item.pricing.sell.price === 0) {
        return `${this.$i18n.t('freeCancellationsUntil')} ${to} \n`
      } else if (from == to) {
        return `${this.$i18n.t('forCancellationsOn')} ${from} ${this.$i18n.t('beACharge')} ${item.pricing.sell.price} ${this.$i18n.t('euros')}. \n`
      } else {
        return `${this.$i18n.t('forCancellationsBetween')} ${from} ${this.$i18n.t('and')} ${to} ${this.$i18n.t('beACharge')} ${item.pricing.sell.price} ${this.$i18n.t('euros')}. \n`
      }
    },
    rateSelected() {
      this.$emit('rateSelected', {bookToken: this.bookToken, price: this.price})
    },
    averagePrice() {
      return (this.price/this.nights).toFixed(2)
    },
    showPolicies() {
      this.showModal = !this.showModal
    },
    checkNoRefund () {
      return Constants.NO_REFUNDABLE_IDS.includes(this.rateId)
    },
  }
}
</script>

<style lang="scss" scoped>
.show-more {
  margin-left: 5px;
  color:var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}
@import "@/scss/color-mixin.scss";
#choose-room.room-picker {
  margin:0 auto;
}
.room {
  margin: 0;

  & > p {
    margin: 0 20px;
  }
  p.text-left {
    margin-bottom:10px;
    text-align:left;
  }
  p.plan {
    font-size: 18px;
    text-transform: uppercase;
  }

  p.room-type {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color:var(--primary-color);
  }

  span.best-deal {
    background: var(--primary-color);
    border-radius: 14px;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0 16px;
    margin-left: 16px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
  }

  .card {
    margin: 0 0 40px 10px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;

    .price {
      padding: 20px 0px 0px 0px;
      text-align:center;
      background:lighten(grey, 45%);
      .average {
        margin-bottom: 20px;
        justify-content: space-between;

        p {
          font-weight: 600;
          font-size: 14px;
          align-self: center;
        }

        p:first-child {
          font-weight: bold;
          font-size: 26px;
          color:var(--primary-color);
        }
      }
      .total {
        display: flex;
        justify-content: space-between;

        .rooms-nights {
          font-size: 14px;

          p:first-child {
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        p.price-calc {
          width: 100%;
          font-weight: bold;
          font-size: 30px;
          align-self: center;
          color:var(--primary-color);
        }
      }

      .total.lg {
        margin-bottom: 20px;
      }
    }
    .align-elements {
      text-align:left;
      padding:0 10px 0 10px;
      height:50px;
      margin-bottom:10px;

      p:before {
        margin-right: 5px;
        vertical-align: sub;
        content: var(--icon-circular-check);
      }

    }

    a.btn.btn-primary {
      line-height: 40px;
      height: 40px;
      color: white;
      margin-top: 5px 20px 30px 20px;
      display: block;
      background-color: var(--primary-color);

      &:hover {
        background-color: var(--hover-color);
      }
    }

    p.more-info {
      margin: 0 20px;
      font-size: 14px;
      font-weight: bold;
      text-align: right;

      &::after {
        content: url('../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg');
      }
    }
  }
}

@media (max-width: 768px) {
  .room {
    .room-type {
      margin-top:10px;
    }
    .reservation-info p {
      margin:10px 15px 10px 15px;
    }
  }
}

@media (min-width: 768px) {
  .room {
    > p.plan,
    > p.room-type > span {
      display: none;
    }

    p.room-type {
      margin-left: 0;
    }

    .card {
      margin-left: 0;
      //min-height: 200px;
      display: grid;
      grid-template-columns: 30% 50% 20%;
      grid-template-areas:
      "roomType details reserve"
      "roomType cancellationPolicies bookNow";

      .plan-room-type {
        display: flex;
        vertical-align: middle;
        flex-direction: column;
        justify-content: center;
      }

      .room-info {
        display: block;
        grid-area: details;
        align-self: end;
        padding-bottom: 7px;
        padding-left: 80px;
        border-left: 1px solid #E9E9E9;

        p {
          font-size: 20px;
        }

        a {
          text-decoration: none;
          color: $primary-black;
          font-weight: bold;

          &::after {
            content: url('../../assets/hotel-detail/ic_button_deactive_dropdown_active_right.svg');
            margin-left: 10px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .price {
        grid-area: reserve;
        margin:0;
        padding:12px;

        div.per-night {
          flex-direction: column-reverse;
          align-content: flex-end;

          p:first-child {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
          }

          p {
            align-self: flex-end;
            font-weight: bold;
            font-size: 26px;
          }

          span.best-deal {
            display: block;
            min-width: 107px;
            min-height: 28px;
            font-weight: bold;
            line-height: 28px;
            text-align: center;
            align-self: flex-end;
            margin-top: 40px;
            margin-bottom: 10px;
          }
        }

        .total {
          display: none;
        }

        .total.lg {
          display: flex;
          flex-direction: column;

          p:first-child {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
          }

          p.price-calc {
            align-self: flex-end;
            font-size: 33px;
          }

          p:last-child {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }

      a.btn.btn-primary {
        grid-area: bookNow;
        align-self: end;
        justify-self: end;
        margin-top:5px;
      }


      .reservation-info {
        display: flex;
        vertical-align: middle;
        text-align: left;
        flex-direction: column;
        justify-content: center;

        .type-meal {
          margin-bottom:20px;
        }

        .cancel-policies {
          padding-top: 20px;
          text-align: left;

          .cp-title {
            font-weight: bold;
            color:var(--primary-color);
          }

          .policies {
            display: flex;
            padding: 5px 0;

          }

        }
      }
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  border-bottom: 1px solid #EFEFEF;
  position: relative;
  button {
    padding: 0;
    position: absolute;
    left: -25px;
    top: -15px;
    background: white;
    border: 1px solid #EFEFEF;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  }
}

.modal-header h3 {
  margin-top: 0;
  color:var(--primary-color);
}

.modal-body {
  text-align: justify;
  margin: 20px 0;
  p {
    padding: 5px 0
  }
}

.modal-default-button {
  float: left;
}

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
