<template>
  <section class="room-picker" id="choose-room">
    <h2>{{ $t('chooseYourRoom') }}</h2>
    <purchase-card
        v-for="(rate, index) in reservationInfo.roomRates"
        :key="index"
        :bookToken="rate.bookToken"
        :rooms="rate.rooms"
        :nights="calculateDays()"
        :price="rate.pricing.sell.price"
        :cancellationPolicies="rate.cancellationPolicies"
        :checkIn="reservationInfo.checkIn"
        :checkOut="reservationInfo.checkOut"
        :meal="rate.meal.name"
        :rateId="rate.rateID"
        :plan="index+1"
        :btnActive="true"
        @rateSelected='prebookRate'
    >
    </purchase-card>
  </section>
</template>

<script>
import PurchaseCard from '@/components/booking/PurchaseCard'

export default {
  components: {
    PurchaseCard
  },
  props: {
    reservationInfo: Object,
    modelValue: String
  },
  methods: {
    calculateDays() {
      let checkIn = new Date(this.reservationInfo.checkIn);
      let checkOut = new Date(this.reservationInfo.checkOut);
      let diffTime = Math.abs(checkOut - checkIn);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    prebookRate(ev) {
      this.$emit("prebookRate", ev)
    }
  }
}
</script>

<style lang="scss" scoped>
.room-picker {
  width:100%;
  padding-top: 60px;
  border-bottom: 1px solid #E9E9E9;

  h2 {
    font-size: 20px;
    margin: 0 20px 20px 20px;
    color: $primary-black;
  }

  .filters {
    margin: 0 20px 40px 20px;

    button {
      margin-right: 20px;
      background-color: $primary-black;
      border: none;
      border-radius: 5px;
      padding: 10px 10px 9px 10px;
      outline: none;
    }

  }
}

@media (min-width: 768px) {
  .room-picker {
    padding-bottom: 80px;
    width:70%;
    margin:0 auto;
    h2 {
      font-size: 34px;
      margin-left: 0;
    }

    .filters {
      margin-left: 0;
    }
    .room .card {
      padding:10px;
    }
  }
}

@media (max-width: 1600px) {
  .room-picker {
    width:100%;
    margin:0;
    padding:14px;
  }
}
</style>
