<template>
      <h1 :style="{ fontSize: hotelNameFontSize + 'px' }">{{ hotel.name }}</h1>
      <div class="stars">
        <img
            v-for="(star, i) in hotel.stars"
            class="star"
            :key="`stars-hotel-card-${hotel.hotelId}-${i}`"
            src="@/assets/ic_hotel_card_star_1.svg"
            :alt="`one star of ${hotel.stars}`"
        >
      </div>
      <p class="address">{{ hotel.address }} {{ hotel.city }}</p>
      <p class="distances">
        <span class="distance-center">{{ hotel.km_to_center }} {{ $t('kmFromCenter') }}</span>
        <span class="distance-congress">{{ hotel.km_to_congress }} {{ $t('kmFromCongress') }}</span>
      </p>
      <div class="share-reserve-cta">
        <div class="share" @click="shareMenuToggle = !shareMenuToggle">
          <img
              src="@/assets/ic_detail_share.svg"
              alt="icon to tap and share current hotel"
              class="share"
          >
          <div :class="{open: shareMenuToggle}" class="options">
            <ul>
              <li>
                <img src="@/assets/contact-footer/bt_facebook_default.svg" alt="facebook logo">
                <a
                    :href="`https://www.facebook.com/sharer/sharer.php?u=${shareUrl()}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('shareFacebook') }}</a>
              </li>
              <li>
                <img src="@/assets/contact-footer/bt_Twitter.svg" alt="twitter logo">
                <a
                    :href="`https://twitter.com/intent/tweet?url=${shareUrl()}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('shareTwitter') }}</a>
              </li>
              <li>
                <img src="@/assets/contact-footer/bt_linkedin.svg" alt="linkedin logo">
                <a
                    :href="`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl()}&title=&summary=&source=`"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('shareLinkedIn') }}</a>
              </li>
              <li>
                <img src="@/assets/contact-footer/bt_whatsapp.svg" alt="Whatsapp logo">
                <a
                    :href="`https://wa.me/?text=${shareUrl()}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('shareWhatsapp') }}</a>
              </li>
              <li>
                <img src="@/assets/contact-footer/bt_email.svg" alt="Whatsapp logo">
                <a
                    :href="`mailto:?body=${shareUrl()}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('shareEmail') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <a v-if="btnActive" :href="hotel.booking_url" target="_blank" rel="noopener noreferrer" class="btn btn-primary">{{ $t('bookNow') }}</a>
      </div>
</template>

<script>
import HotelNavBar from '@/components/hotel-detail-page/HotelNavBar'
import Gallery from '@/components/hotel-detail-page/Gallery'

import {mapGetters} from 'vuex'

export default {
  name: 'detailHeader',
  components: {
    HotelNavBar,
    Gallery
  },
  props: {
    hotel: Object,
    btnActive: Boolean
  },
  methods: {
    shareUrl() {
      return this.site.url + '/hotel/' + this.hotel.id
    }
  },
  computed: {
    ...mapGetters([
      'site',
      'cityCenterLat',
      'cityCenterLng',
      'congressLat',
      'congressLng'
    ]),
    hotelNameFontSize() {
      if (!this.hotel.name) {
        return 34
      } else if (this.hotel.name.length >= 35) {
        return 33
      } else if (this.hotel.name.length >= 25) {
        return 36
      }
      return 34
    }
  },
  data: function () {
    return {
      shareMenuToggle: false,
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 5
        },
        {
          breakpoint: '768px',
          numVisible: 3
        },
        {
          breakpoint: '560px',
          numVisible: 1
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/color-mixin.scss";

.hotel-detail-header {
  padding-top: 10px;
  margin: 0;

  .thumbnails {
    display: none;
  }

  .hotel-info {
    margin: 0 20px 20px 20px;

    a {
      display: block;
      text-decoration: none;
      color: $secondary-black;

      &:hover {
        text-decoration: underline;
      }
    }

    p,
    a {
      font-size: 14px;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 22px;
      color: $primary-black;
      margin-bottom: 10px;
    }

    .stars {
      margin-bottom: 30px;

      img {
        padding-right: 4px;
      }
    }

    .distances {
      font-size: 14px;
      display: flex;

      &:before {
        content: var(--icon-walking-distance);
      }

      span.distance-center {
        border-right: 1px solid #b6b6b6;
        padding-right: 10px;
        margin-right: 10px;
      }
    }

    .share-reserve-cta {
      height: 40px;
      display: flex;
      justify-content: space-between;

      .share {
        position: relative;
        align-self: center;

        .options {
          display: none;

          &.open {
            display: block;
            position: absolute;
            z-index: 9999;
            width: 200px;
            background: #ffffff;
            border: 1px solid #e9e9e9;
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.15);
            border-radius: 5px;

            ul {
              list-style: none;
              padding: 0;
              margin: 20px 20px;

              li {
                margin-bottom: 15px;
                font-size: 16px;
                color: $secondary-black;
                display: flex;

                a {
                  margin: auto 10px;
                }
              }
            }
          }

          &::before {
            content: " ";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid white;
            position: absolute;
            top: -5px;
            left: 10px;
          }
        }
      }

      .btn {
        height: 100%;
        background-color: var(--primary-color);
        color: $primary-color-white;
        line-height: 40px;
        margin-bottom: 0;

        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }

  .images {
    margin: 0;
  }
}

.p-galleria-item {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  width: 100%;
  max-height: 280px;
}

@media (min-width: 768px) {
  .hotel-detail-header {
    .hotel-info {
      margin: 0 0 40px 0;
      min-height: 180px;
      display: grid;
      grid-template-rows: 10% 50% 60px;
      grid-template-columns: 30% 20% 15% 5% 30%;
      grid-template-areas:
        "goBack . . . ."
        "hotelName hotelName stars stars ."
        "address distances distances reserve reserve";

      a:first-child {
        grid-area: goBack;
        font-size: 17px;
      }

      h1 {
        grid-area: hotelName;
        font-size: 48px;
        align-self: center;
        margin-bottom: 0;
      }

      .stars {
        grid-area: stars;
        margin-bottom: 0;
        align-self: center;

        img {
          height: 30px;
        }
      }

      .address {
        grid-area: address;
        align-self: center;
        font-size: 16px;
        border-right: 1px solid #b6b6b6;
        margin-right: 20px;
        margin-bottom: 0;
      }

      .distances {
        grid-area: distances;
        align-self: center;
        margin-bottom: 0;
        font-size: 16px;
      }

      .share-reserve-cta {
        align-self: center;
        grid-area: reserve;
        justify-content: flex-end;
        height: auto;

        img {
          margin-right: 30px;
        }

        .btn {
          height: 60px;
          width: 160px;
          line-height: 60px;
        }

        .share {
          .options.open {
            width: 225px;
          }
        }
      }
    }

    .images-nav-bar {
      display: flex;
      flex-direction: column-reverse;

      .hotel-nav {
        margin-bottom: 0;
        box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
      }

      .images {
        img {
          min-height: 642px;
        }
      }

      .thumbnails {
        display: block;
        margin: 0;

        img {
          height: 108px;
          width: 159px;
          margin-right: 10px;
        }
      }
    }
  }

  .carousel-inner {
    min-height: 642px;
    width: 100%;
    max-height: 280px;
  }
}
</style>
