<template>
  <spinner :visible="spinnerVisible"></spinner>
  <div>
    <navbar :class="{ show: true }" :showLinks="false"></navbar>
    <search-filters
        ref="search_filters"
        @search="searchAvailability($event)">
    </search-filters>
    <div class="container-detail">
      <section class="hotel-detail-header">
        <section class="hotel-info">
        <detail-header :hotel="hotel_data" :btnActive="false"></detail-header>
        </section>
      </section>
      <div v-if="roomRates.length">
        <purchase-picker :reservationInfo="reservationInfo" @prebookRate="prebooking">
        </purchase-picker>
      </div>
      <div v-else class="no-result">
        <div>
          <img src="@/assets/list-page/ic_no_results.svg" alt="">
          <p>{{ $t('noResults') }}</p>
        </div>
      </div>
    </div>
  </div>
  <contact-footer :showFooter="true"></contact-footer>
  <legal-footer :showFooter="true"></legal-footer>
</template>

<script>
import {mapGetters} from "vuex";
import bookingService from "@/services/bookingService";
import siteService from "@/services/siteService";
import PurchasePicker from '@/components/booking/PurchasePicker'
import Navbar from "@/components/Navbar";
import Filters from '@/components/list-page/Filters'
import FullPage from '@/components/FullPage';
import SearchFilters from "@/components/booking/SearchFilters";
import DetailHeader from "@/components/hotel-detail-page/DetailHeader";
import ContactFooter from "@/components/landing-page/ContactFooter";
import LegalFooter from "@/components/landing-page/LegalFooter";
import Spinner from "@/components/Spinner";


export default {
  name: "PreBooking",
  computed: {
    ...mapGetters(["congressName", "dataIsReady", "dataHotelsIsReady", "hotels", "language"]),
    hotel_data() {
      if (this.hotel.id) {
        if (this.dataHotelsIsReady) {
          return this.$store.getters.hotel(Number(this.hotel.id));
        }
        else if (this.dataIsReady) {
          return this.$store.getters.staticHotel(Number(this.hotel.id));
        }
      }
      return {}
    },
  },
  components: {
    Spinner,
    SearchFilters,
    PurchasePicker,
    Navbar,
    Filters,
    FullPage,
    DetailHeader,
    ContactFooter,
    LegalFooter
  },
  data() {
    return {
      hotel: {},
      availability: {
        distribution: [],
        checkIn: null,
        checkOut: null,
      },
      token: null,
      bookToken: null,
      roomRates: [],
      reservationInfo: {},
      spinnerVisible: false
    }
  },
  methods: {
    searchAvailability(e) {
      this.spinnerVisible = true

      this.availability.checkIn = this.formatDateToSearch(e.range_dates.start)
      this.availability.checkOut = this.formatDateToSearch(e.range_dates.end)

      //Filling availability.distribution as required by the service
      this.availability.distribution = []
      e.room_distribution.forEach((r) => {
        this.availability.distribution.push({
          "index": r.index,
          "passengerAges": Array.from({length: r.passengers}, _x => 30)
        })
      })

      bookingService.getAvailability(
          this.hotel.original_id,
          this.availability.checkIn,
          this.availability.checkOut,
          this.availability.distribution,
          this.language
      )
          .then(data => {
            try {

              this.roomRates = data.hotels[0].roomRates
              this.token = data.token

              this.reservationInfo = data.hotels[0]
              this.spinnerVisible = false
            }

            catch (error) {
              throw new Error(error)
            }
          })
          .catch((_err) => {
            this.spinnerVisible = false
            this.roomRates = []
            this.$notify({
              type: "error",
              title: "Error",
              text: this.$i18n.t('unableAvailability')
            });

          })

    },
    formatDateToSearch(date) {
      // return a str YYYY-MM-DD
      return date.toLocaleDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'}).split('/').reverse().join('-')
    },
    prebooking(ev) {
      this.spinnerVisible = true
      this.bookToken = ev.bookToken

      bookingService.prebooking(
          this.token,
          this.bookToken
      )
          .then(data => {
            this.$store.commit('SET_PREBOOKING', {
              data: data,
              distribution: this.availability.distribution,
              hotel_id: this.hotel.id, 
              prebook_price: ev.price
            })
            this.$router.push({
              name: 'booking', params: {
                id: this.hotel.original_id
              }
            })
          })
          .catch((_err) => {
            this.spinnerVisible = false
            this.$notify({
              type: "error",
              title: "Error",
              text: this.$i18n.t('offerCouldNotLoaded')
            });
          })
    },
  },
  beforeMount() {
    const original_id = this.$route.params.id
    siteService.getHotel(original_id)
        .then(data => {
          this.hotel = data
          this.$refs.search_filters.search()
        })
        .catch((err) => {
          if (err.response.status === 404) { 
            this.$router.push('/404')
          }
        })
  }
}
</script>

<style lang="scss">
  .container-detail {
    max-width: 1200px;
    margin: 0 auto;
  }

  .hotel-info {
    margin-top: 150px !important;
  }

  .no-result {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    p {
      font-size: 28px;
      color: $secondary-black;
      font-family: "Frank Ruhl Libre", sans-serif;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
      .hotel-info {
        margin-top: 160px !important;
      }
      .no-result {

        p {
          font-size: 18px;
        }
    }
  }

  @media (max-width: 600px) {
    .hotel-info {
      margin-top: 20px !important;
    }
  }

</style>
