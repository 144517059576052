<template>
  <vueper-slides
      class="no-shadow thumbnails"
      ref="vueperslides2"
      @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
      :visible-slides="9"
      fixed-height="75px"
      :bullets="false"
      :touchable="false"
      :gap="1.5"
      :arrows="false">
    <vueper-slide
        v-for="(slide, i) in images"
        :key="i"
        :image="slide"
        @click="$refs.vueperslides2.goToSlide(i)">
    </vueper-slide>
  </vueper-slides>

  <vueper-slides
    :slideRatio="1/2"
    :breakpoints="breakpoints"
    ref="vueperslides1"
    @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })">
  <vueper-slide v-for="(slide, i) in images" :key="i" :image="slide"/>
</vueper-slides>
</template>
<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: {VueperSlides, VueperSlide},
  props: {images: Array},
  data: () => ({
    breakpoints: {
      600: {
        slideRatio: 2 / 3,
      },
    },
  })
}
</script>

<style lang="scss">
.thumbnails {
  margin: auto;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}

.thumbnails .vueperslides__parallax-wrapper{
  margin-top: 0.5rem;
}

</style>