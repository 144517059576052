<template>
  <div class="dropdown-wrapper">
    <img class="btn-icon" src="@/assets/filters-icons/ic_card_room_black.svg" alt="">
    <dropdown id="room-distribution" :class-name="'is-big'" ref="">
      <template v-slot:btn>
        <template v-if="!isSmallButton"> {{ showDistribution() }}</template>
      </template>
      <template v-slot:body>
        <div v-for="i in this.rooms" :key="i">
          <div class="margin-y-5">{{ $tc('room', 1) }} {{ i }}</div>
          <div class="formControlContent">
            <select-options
                @change="hasChange"
                v-model="distribution[i - 1].passengers"
                :list="passengersOptions"
            >
            </select-options>
            <button class="deleteRoom" v-if="rooms > 1 && rooms === i" v-on:click="removeRoom"><img class="delete-icon"
                                                                                                    src="@/assets/icons/ic_hotel_delete.svg"
                                                                                                    alt=""></button>
          </div>
        </div>
        <button class="btn btn-primary margin-top-10" v-if="rooms < MAX_ROOMS_BOOKING" v-on:click="addRoom">
          {{ $t('addRoom') }}
        </button>

      </template>
    </dropdown>
  </div>
</template>

<script>

import Dropdown from '@/components/Dropdown'
import SelectOptions from '@/components/SelectOptions'
import Constants from '@/constants'

export default {
  components: {
    Dropdown,
    SelectOptions
  },
  props: {
    modelValue: Array
  },
  watch: {
    '$i18n.locale': function () {
      this.passengersOptions = this.createPassengersOptions()
    }
  },
  data() {
    return {
      MAX_ROOMS_BOOKING: Constants.MAX_ROOMS_BOOKING,
      MAX_PASSENGERS_BOOKING: Constants.MAX_PASSENGERS_BOOKING,
      passengersOptions: [],
      rooms: 1,
      distribution: [],
    }
  },
  methods: {
    removeRoom() {
      this.distribution.pop()
      this.rooms -= 1
    },
    addRoom() {
      this.distribution.push(
          {
            index: this.rooms + 1,
            passengers: 1
          }
      )
      this.rooms += 1

      this.hasChange()
    },
    totalPassengers() {
      return this.distribution.reduce((total, obj) => parseInt(obj.passengers) + total, 0)
    },
    showDistribution() {
      const text_room = this.rooms === 1 ? this.$i18n.tc('room', 1).toLowerCase() : this.$i18n.tc('room', 2).toLowerCase()
      const text_passenger = this.totalPassengers() === 1 ? this.$i18n.tc('guest', 1).toLowerCase() : this.$i18n.tc('guest', 2).toLowerCase()

      return `${this.rooms} ${text_room} ${this.$i18n.t('for')} ${this.totalPassengers()} ${text_passenger}`
    },
    createPassengersOptions() {
      let list = [];
      let passenger_text = "";
      for (let i = 1; i <= Constants.MAX_ROOMS_BOOKING; i++) {
        passenger_text = i === 1 ? this.$i18n.tc('guest', 1).toLowerCase() : this.$i18n.tc('guest', 2).toLowerCase()
        list.push({value: i, name: `${i} ${passenger_text}`});
      }

      return list;

    },
    hasChange() {
      this.$emit('update:modelValue', this.distribution)
    }
  },
  mounted() {
    //Build static passengers options
    this.passengersOptions = this.createPassengersOptions();
    //Set default distribution
    this.distribution.push({
      index: 1,
      passengers: 1
    })
  }
}
</script>

<style lang="scss" scoped>
.filters {
  margin-top: 60px !important;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  height: 100px;
  box-shadow: 0px 1px 10px gray;
}

.margin-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.formControlContent {
  border-bottom: 1px solid #666;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: right;
  overflow: hidden;

  select {
    float: left;
    display: block;
    width: 80%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $primary-black;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid gray;
    border-radius: .25rem;
  }
}

.deleteRoom {
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem 0;
  cursor: pointer;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;

  .delete-icon {
    color: darken(red, 50%);
    width: 30px;
  }

  &:hover,
  &:focus,
  &:active {
    border: 0;
    outline: none;
  }
}

.filters > .filter {
  margin: 7.5px;
}
</style>