<template>
    <select
        :value="modelValue"
        @change='$emit("update:modelValue", $event.target.value); $emit("change", $event.target.value)'>
      <option v-for="item in list" :value="item.value" :key="item.value">{{ item.name }}</option>
    </select>
</template>

<script>

export default {
  props: {
    list: Object,
    modelValue: Number
  },
}
</script>
