<template>
  <div class="dropdown-wrapper">
    <img class="btn-icon" src="@/assets/filters-icons/ic_calendar_black.svg" alt="">
    <dropdown id="" :class-name="'is-big'" :style-object="{width: 'auto'}" ref="">
      <template v-slot:btn>
        <template v-if="!isSmallButton"> {{ showDates() }}</template>
      </template>
      <template v-slot:body>

        <range-picker v-model="value" :attrs="calendarAttrs"></range-picker>

      </template>
    </dropdown>
  </div>
</template>

<script>

import Dropdown from '@/components/Dropdown'
import RangePicker from '@/components/RangePicker'

export default {
  components: {
    Dropdown,
    RangePicker
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

  },
  data() {
    return {
      calendarAttrs: [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'outline',
          },
          dates: new Date(),
        },
      ]
    }
  },
  props: {
    modelValue: Object
  },
  methods: {
    showDates() {
      if (Object.keys(this.value).length === 0)
        return 'Select dates'
      return `${this.$i18n.t('fromLabel')} ${this.value.start.toLocaleDateString()} ${this.$i18n.t('toLabel')} ${this.value.end.toLocaleDateString()}`
    }
  },
}
</script>
