<template>
  <section class="hotel-nav">
    <div class="links">
      <a href="#description">{{ $t('description') }}</a>
      <a href="#choose-room">{{ $t('chooseRoom') }}</a>
      <a href="#location">{{ $t('location') }}</a>
      <a href="#ammenities">{{ $t('amenities') }}</a>
      <a href="#similar">{{ $t('similarHotels') }}</a>
    </div>

    <div class="gradient">

    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.hotel-nav {
  margin: 0;
  position: relative;

  .links {
    min-height: 50px;
    overflow: auto;
    white-space: nowrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-bottom: 1px solid #E9E9E9;
    // @Todo fix this
    margin-top: -2px;

    a:first-child {
      color: $primary-black;
      padding-left: 18px;
    }

    a {
      align-self: center;
      padding-right: 32px;
      font-weight: 600;
      text-decoration: none;
      color:#A8A8A8;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.7));
    pointer-events: none;
  }
}

@media (min-width: 768px) {
  .hotel-nav {
    .links {
      a {
        justify-self: center;
      }
    }

    .gradient {
      display: none;
    }
  }
}
</style>
