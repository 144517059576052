<template>
  <div class="filters">
    <room-distribution class="margin-left-20" v-model="room_distribution"></room-distribution>
    <date-selector class="margin-left-20" v-model="range_dates"></date-selector>

    <a @click="search" target="_blank" rel="noopener noreferrer" class="btn btn-primary margin-left-20">{{ $t('search') }}</a>
  </div>
</template>

<script>
import RoomDistribution from '@/components/booking/RoomDistribution'
import DateSelector from '@/components/booking/DateSelector'
import {mapGetters} from 'vuex'

export default {
  components: {
    RoomDistribution,
    DateSelector,
  },
  computed: {
    ...mapGetters({site: 'site'})
  },
  data() {
    return {
      room_distribution: [{index: 1, passengers: 1}],
      range_dates: {
        start: this.getCurrentStartDate(),
        end: this.getCurrentEndDate()
      }
    }
  },
  methods: {
    getCurrentStartDate() {
      let startDate = localStorage.getItem("currentStartDate") || this.$store.getters.site.start_at
      return new Date(new Date(startDate).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
    },
    getCurrentEndDate() {
      let endDate = localStorage.getItem("currentEndDate") || this.$store.getters.site.finish_at
      return new Date(new Date(endDate).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
    },
    search() {
      this.$emit('search', {
        room_distribution: this.room_distribution,
        range_dates: this.range_dates
      })
    },
    showChangeAlert() {
      this.$notify({
        type: "info",
        title: "Info",
        text: this.$i18n.t('searchCriteria'),
        ignoreDuplicates: true
      });
    }
  },
  watch: {
    room_distribution: {
      handler(_val) {
        this.showChangeAlert();
      },
      deep: true
    },
    range_dates: function (val) {
      //do something when the data changes.
      if (val) {
        this.showChangeAlert()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  margin-top: 60px !important;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  height: 100px;
  box-shadow: 0px 1px 10px gray;
}

.margin-left-20 {
  margin-left: 20px;
}

a.btn.btn-primary {
  line-height: 40px;
  height: 40px;
  color: white;
  display: block;
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--hover-color);
  }
}

.filters > .filter {
  margin: 7.5px;
}

@media (max-width: 600px) {
  .filters {
    display: block;
    height: auto;
    position: relative;

    .dropdown-wrapper.margin-left-20 {
      margin: 4px 0;
      width: 100%;
    }

    .btn.btn-primary.margin-left-20 {
      margin: 4px 0;
      width: 100%;
    }
  }
  .bp-dropdown__btn > span {
    display: block;
    width: 100% !important;
  }
}

</style>